* {
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

:root {
  --main-red-color: rgb(255, 7, 3);
  --hover-transition: ease 0.3s;
  --drop-shadow: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.5));
}

body {
  max-width: 100vw;
  min-height: 100vh;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  color: rgb(32, 32, 32);
  cursor: default;
}

button {
  cursor: pointer;
}

/********************************** NAV **********************************/

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  height: 15vh;
  width: 100%;
}

.swp-logo {
  width: 200px;
}

.nav-logo {
  margin: 5vh;
  filter: var(--drop-shadow);
  background: none;
}

.nav-link {
  text-decoration: none;
  margin: 0 6vw 5vh 0;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 600;
  margin-left: auto;
  filter: var(--drop-shadow);
  background: none;
  font-family: 'Raleway', sans-serif;
  padding-bottom: 0.5rem;
  border-bottom: solid 2px rgba(255, 255, 255, 0);
}

.nav-link:focus {
  outline: var(--main-red-color);
  transition: var(--hover-transition);
}

.nav-link:hover {
  border-bottom: solid 2px var(--main-red-color);
  transition: var(--hover-transition);
}

/********************************** BACKGROUND IMAGE **********************************/

.image-background {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 1.75rem;
}

.image-background h1 {
  filter: var(--drop-shadow);
}

/********************************** INFO HEADING **********************************/

.info-heading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5vh 30%;
  text-align: center;
}

.info-heading-container h1 {
  margin: 2rem 0;
  color: var(--main-red-color);
  font-weight: 600;
  font-size: 2.5rem;
}

.info-heading-container h2 {
  font-size: 1.25rem;
  font-weight: 300;
  margin: 0 15%;
}

/********************************** RED BUTTON **********************************/

.red-btn {
  background: none;
  color: var(--main-red-color);
  border: solid 1px var(--main-red-color);
  border-radius: 8px;
  font-size: 1.25rem;
  padding: 0.75rem 1rem;
  margin-top: 1.5rem;
  text-decoration: none;
}
.red-btn:hover {
  color: #fff;
  background-color: var(--main-red-color);
  transition: var(--hover-transition);
}

/********************************** INFO TEXT BLOCK **********************************/

.info-block-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-around;
  align-items: top;
  margin: 8vh 5vw;
}

.info-block-item {
  text-align: center;
  margin: 2vh 10vw;
}
.info-block-item h2 {
  color: var(--main-red-color);
  padding: 1rem;
  font-size: 2rem;
  text-transform: lowercase;
}

.info-block-item p {
  font-size: 1.2rem;
  text-transform: lowercase;
}

/********************************** SMALL LOGOS **********************************/

.image-logo-block {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-items: center;
  align-items: center;
  width: 75%;
  margin: auto;
  padding: 5%;
}

.image-logo-block img {
  width: 70px;
}

/********************************** GALLERY LINKS **********************************/

.gallery-logo-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0vh 0 5vh 0;
  padding: 0 13vw;
}

.gallery-logo {
  position: relative;
  cursor: pointer;
  padding: 1rem;
  margin: auto;
  border-bottom: solid 3px rgba(0, 0, 0, 0);
}

.gallery-logo:hover {
  transition: var(--hover-transition);
  border-bottom: solid 3px var(--main-red-color);
}

.selected-gallery {
  border-bottom: solid 3px var(--main-red-color);
}

.gallery-logo img {
  width: 250px;
  pointer-events: none;
}

.gallery-logo h3 {
  position: absolute;
  bottom: 20px;
  left: 25px;
  color: var(--main-red-color);
  background-color: #fff;
  padding: 0.3rem;
  pointer-events: none;
}

/********************************** PHOTOGRAPHY **********************************/

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: rgba(231, 231, 231, 0.336);
  padding: 2rem;
  max-width: 1500px;
  margin: auto;
}

.gallery-container img {
  width: 400px;
  max-height: 265px;
  object-fit: cover;
  object-position: 0 20%;
  margin: 1rem;
  filter: var(--drop-shadow);
  cursor: pointer;
}

/********************************** MODAL **********************************/

.img-modal {
  max-width: 80vw;
  max-height: 80vh;
}

.modal {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/********************************** BIOG **********************************/

.biog-container {
  display: flex;
  align-items: center;
  padding: 20vh 15vw 5vh;
  background-color: rgb(51, 51, 51);
  color: #fff;
  min-height: 75vh;
}

.image-container {
  background-color: #fff;
  padding: 0.5rem;
  margin-right: 5rem;
}

.biog-container img {
  width: 300px;
}

.biog-text a {
  color: var(--main-red-color);
}

.biog-text {
  font-size: 1.2rem;
}

.biog-text p:last-child {
  margin-top: 3rem;
}

/********************************** SUB FOOTER **********************************/

.sub-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(51, 51, 51);
  padding: 0.5rem 0;
}

.to-top {
  margin: 1.5rem;
  text-decoration: none;
  background: none;
  color: #fff;
  padding-bottom: 0.25rem;
  border-bottom: solid 2px rgb(51, 51, 51);
}

.to-top:hover {
  border-bottom: solid 2px var(--main-red-color);
  transition: var(--hover-transition);
}

.social-icons-container {
  display: flex;
}

.social-icons {
  background-color: #fff;
  height: 35px;
  width: 35px;
  border-radius: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0.3rem;
}

.social-icons a {
  color: rgb(51, 51, 51);
}

.social-icons a:hover {
  color: var(--main-red-color);
  transition: var(--hover-transition);
}

/********************************** FOOTER **********************************/

footer {
  background-color: rgb(31, 31, 31);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-nav-link-container {
  margin: 5vh 0 0;
}

.footer-nav-links {
  color: #fff;
  margin: 0 1.5rem;
  background: none;
  font-size: 1rem;
  text-decoration: none;
  padding-bottom: 0.25rem;
  border-bottom: solid 2px rgb(31, 31, 31);
}

.footer-nav-links:hover {
  border-bottom: solid 2px var(--main-red-color);
  transition: var(--hover-transition);
}

.footer-info {
  margin: 4vh 0;
  text-align: center;
}

.footer-info a {
  color: #fff;
  text-decoration: underline;
}

.footer-info a:hover {
  color: var(--main-red-color);
  transition: var(--hover-transition);
}

.footer-info p {
  margin: 1vh 0 0;
  font-size: 0.8rem;
}

/********************************** MEDIA QUERIES **********************************/

@media (max-width: 1150px) {
  .image-background {
    background-attachment: scroll;
    background-position: 50% 30%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .nav-link {
    font-size: 1.5rem;
  }

  .info-block-container {
    height: 50vh;
    margin: 0vh 2vw;
    padding-top: 6vh;
  }

  .gallery-logo-container {
    padding: 0 0vw;
  }

  .biog-container {
    flex-direction: column;
    padding: 20vh 15vw 5vh;
  }

  .image-container {
    margin-right: 0;
    margin-bottom: 5rem;
  }

  .biog-container img {
    width: 350px;
  }

  .to-top {
    font-size: 2rem;
  }

  .social-icons {
    height: 55px;
    width: 55px;
    margin: 1rem 1rem;
  }

  .footer-nav-links {
    margin: 0 2rem;
    font-size: 2rem;
  }

  .footer-info a {
    font-size: 1.5rem;
  }

  .footer-info p {
    margin: 0.5vh 0 0;
    font-size: 1.25rem;
  }
}

@media (min-width: 2350px) {
  .info-block-item {
    text-align: center;
    margin: 2vh 15vw;
  }
  .gallery-logo img {
    width: 350px;
  }

  .gallery-container img {
    width: 500px;
    max-height: 330px;
  }

  .gallery-container {
    max-width: 2000px;
  }
}
